import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, Icon } from '@mui/material';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ErrorDialog from '@/components/utility/modals/ErrorModal';
import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useUserTracking from '@/hooks/useUserTracking';
import { StripeProductModel } from '@/models/Stripe';
import PaymentAPI from '@/network/PaymentAPI';
import { handleApiError } from '@/utility/api';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '');

const CheckoutForm = ({
  sessionId,
  paymentIntentToken,
  onComplete,
  product,
}: {
  sessionId: string;
  paymentIntentToken: string;
  onComplete: () => void;
  product: StripeProductModel;
}) => {
  const userTracking = useUserTracking();
  const [open, setOpen] = useState<boolean>(false);
  const { isSubscribed } = useSubscription();

  const handleCancelCheckout = () => {
    if (userTracking) userTracking.userCancelledCheckout(product, isSubscribed);
    setOpen(false);
    onComplete();
  };

  useEffect(() => {
    setOpen(!!sessionId && !!paymentIntentToken);
  }, [sessionId, paymentIntentToken]);

  return (
    <>
      <Dialog className="payment-modal" data-testid="payment-modal" open={open} onClose={() => handleCancelCheckout()}>
        <div className="d-flex jc-space-between pos-abs r0 mr32 mt16">
          <Button className="icon-btn m0 ml-auto mb10" onClick={() => handleCancelCheckout()}>
            <Icon>close</Icon>
          </Button>
        </div>
        <EmbeddedCheckout />
      </Dialog>
    </>
  );
};

const StripeCheckout = ({ product, onComplete }: { product: StripeProductModel; onComplete: () => void }) => {
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const userTracking = useUserTracking();
  const { isSubscribed } = useSubscription();

  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const [sessionId, setSessionId] = useState<string>();
  const [paymentIntentToken, setPaymentIntentToken] = useState<string>();

  const confirmPaymentSessionStatus = async () => {
    if (!sessionId) return;
    try {
      const response = await PaymentAPI.getPaymentSessionStatus({ sessionId });
      if (response.data.status === 'complete') {
        onComplete();
        navigate(`/payment-confirmation/${product.platform}?sessionId=${sessionId}`);
      } else {
        setErrorDialogOpen(true);
      }
    } catch (error: unknown) {
      handleApiError({ error });
    }
  };

  const getPaymentIntent = useCallback(async () => {
    if (userTracking) userTracking.userInitiatedCheckout(product, isSubscribed);

    if (product.productType !== 'Subscription') {
      try {
        if (!accountId || !product.draftCampaignId) return;
        const response = await PaymentAPI.getPaymentIntent({
          accountId,
          draftCampaignId: product.draftCampaignId,
          priceId: product.priceId,
        });
        setSessionId(response.data.sessionId);
        setPaymentIntentToken(response.data.clientSecret);
      } catch (error: unknown) {
        onComplete();
        handleApiError({ error });
      }
      return;
    }

    try {
      if (!accountId) return;
      const response = await PaymentAPI.getSubscriptionPaymentIntent({
        accountId,
        priceId: product.priceId,
      });
      setSessionId(response.data.sessionId);
      setPaymentIntentToken(response.data.clientSecret);
    } catch (error: unknown) {
      onComplete();
      handleApiError({ error });
    }
  }, [accountId, isSubscribed, onComplete, product, userTracking]);

  useEffect(() => {
    getPaymentIntent();
  }, [getPaymentIntent]);

  const retryPayment = () => {
    setSessionId(undefined);
    setPaymentIntentToken(undefined);
    setErrorDialogOpen(false);
    getPaymentIntent();
  };

  return (
    <>
      <ErrorDialog
        errorTitle={'ERRORS.PROCESSING-PAYMENT'}
        errorContent={'ERRORS.TRY-AGAIN-OR-CONTACT-SUPPORT'}
        open={errorDialogOpen}
        onRetry={retryPayment}
        onClose={() => {
          setErrorDialogOpen(false);
          onComplete();
        }}
      />
      {sessionId && paymentIntentToken && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret: paymentIntentToken, onComplete: confirmPaymentSessionStatus }}
        >
          <CheckoutForm
            sessionId={sessionId}
            paymentIntentToken={paymentIntentToken}
            onComplete={onComplete}
            product={product}
          />
        </EmbeddedCheckoutProvider>
      )}
    </>
  );
};

export default StripeCheckout;
